import Section from './Section';
import styles from '../../styles/modules/common/cta-banner.module.scss';
import { useEffect, useRef, useState } from 'react';
import { getGeoPos } from '../../utils/location';
import GoogleAutoComplete from '../user-interaction/GoogleAutoComplete';
import Image from '../common/Image';
import { getSelectedCityFromCookie, setSelectedCityInCookie } from '../../utils/cities';
import GoogleSearch from './ui-components/input/UIDropdown/GoogleSearch';
import CITIES_SLUG from '../../city_constants';
import { getParamsObjectFromString, getTruncatedString } from '../../utils';
import {  getLocationFromIPAbstractApi, locationSelectorTap } from '../../tracking/segment/homePage';
import { getPageURL } from '../../utils/tracking';
import router from 'next/router';
import { GetGeoLocation, GetLocationFromIP } from '../../api/home';

declare global {
    interface Window {
        Location: any;
    }
}
interface MainBannerProps {
    products: any[],
    title: string,
    subtitle?: string,
    ctaAction: (e) => void
}
const CtaBanner = (props: MainBannerProps) => {
    
    const { products, ctaAction, title } = props;
    const [toggle, setToggle] = useState<boolean>(false);
    const [location, SetLocation] = useState<string>(getSelectedCityFromCookie() || "Mumbai")
    const [geoError, setGeoError] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [isMobile, setMobile] = useState<boolean>(true)
    useEffect(() => {
        window.innerWidth < 481 ?
            setMobile(true)
            :
            setMobile(false)
    });

    const myRef: any = useRef(null);
    var products_list = products.map !== undefined ? products.map((values, index) => ({ key: index + 1, value: values.cat_slug, text: values.cat_title, category: values.group_name, group_slug: values.group_slug, keyword: values.keyword })) : [];

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            if (toggle)
                document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [toggle]);

    useEffect(()=>{
        let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
        if(params['city'] && getSelectedCityFromCookie()!==params['city'])
        {
            SetLocation(params['city'])
            setSelectedCityInCookie(params['city'],'manual')
        }
    })
    useEffect(()=>{       
        if(!getSelectedCityFromCookie())
        {
            getCity()
        }
    },[])

    useEffect(()=>{
        if(getSelectedCityFromCookie()){
            SetLocation(getSelectedCityFromCookie())
        }
    },[getSelectedCityFromCookie()])

    const handleClick = () => {
        setToggle(!toggle)
        locationSelectorTap({'location':getSelectedCityFromCookie(),...getPageURL(router)}) // Segment Event
    }

    const handleClickOutside = e => {
        if (toggle && myRef && !myRef.current.contains(e.target)) {
            setToggle(false)
            setGeoError(false)
        }
    };

    const detectLocation = async () => {
        let {lat, long}:any = await getGeoPos();
        GetGeoLocation({lat:lat, long}).then((res) => {
            if (res) {
                var adresss = res.data.data[0]["address_components"];
                for (let i = 0; i < adresss.length; i++) {
                    if (adresss[i].types.includes("administrative_area_level_3")) {
                        let city:string = adresss[i].long_name
                        city = CITIES_SLUG[city.toUpperCase()] ? CITIES_SLUG[city.toUpperCase()]["city"] : city.charAt(0) + city.slice(1).toLowerCase()
                        setSelectedCityInCookie(city,'auto')
                        SetLocation(city)
                        setToggle(false)
                        setGeoError(false)
                    }
                }
            }
        })
        .catch(err => {
            if (err) {
                if (err.message) {
                    setGeoError(true)
                }
            }
        })
    }

    const getCity = async () => {   
        GetLocationFromIP().then((res) => {
            if (res) {
                let city=res.data.city ||  getSelectedCityFromCookie() || ''
                if(city){
                    let res_city = CITIES_SLUG[city.toUpperCase()] ? CITIES_SLUG[city.toUpperCase()].city : city
                    SetLocation(res_city)
                    setSelectedCityInCookie(res_city,'auto')
                }
                else{
                    SetLocation('Mumbai')
                }
                getLocationFromIPAbstractApi({source:'homepage'})
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const openDropdown = () => {
        setToggle(false);
        setOpen(true)
        locationSelectorTap({'location':getSelectedCityFromCookie(),...getPageURL(router)}) // Segment Event
    }

    return (
        <Section className={styles.cta_banner}>
            <div className={`${styles._container} container`}>
                <h1 className={styles._title}>{title} </h1>
                <div className={styles._cta}> 
                    {!isMobile && <>
                        <div style={{ "margin": "auto 15px" }}>
                            <Image width="35" height="35" src={"/static/images/home/map-pin.webp"}  alt="map pin"/>
                        </div>
                        <p className={styles._city} onClick={handleClick} suppressHydrationWarning>
                            {getTruncatedString(location, 10)}
                        </p>
                    </>
                    }
                    {isMobile && 
                        <>
                            <div className={styles.mobile_location}>
                                <div className={styles.location_image}>
                                    <Image width="20" height="20" src={"/static/images/home/map-pin.webp"} alt="map pin" />
                                </div>
                                <p className={styles._city} onClick={openDropdown} suppressHydrationWarning>
                                    {getTruncatedString(location, 10)}
                                </p>
                            </div>
                        </>
                    }
                    {isMobile && toggle && <>
                        <div className={styles.location_popup} ref={myRef}>
                            <div className={styles.location_text}>
                                <div className={styles.location_image}>
                                    <Image width="20" height="20" src={"/static/images/home/location-trans.png"} alt="location trans" />
                                </div>
                                <p onClick={detectLocation}>Select your locality for a customised experience</p>
                            </div>
                            <div className={styles.location_options}>
                                <p className={styles.option_manual} onClick={openDropdown}>Type Manually</p>
                                <p className={styles.option_auto} onClick={detectLocation}>Detect Location</p>
                            </div>
                            </div>
                    </>
                    }
                    { isMobile && toggle && <span className={styles.blinker}></span>}
                    <GoogleSearch
                        search
                        className={styles._dropdown}
                        labelClass={styles._dropdown_label}
                        onChange={ctaAction}
                        onClick = {(city)=>{SetLocation(city)}}
                        placeholder="Select a device" options={products_list}
                        log={{ "page": "Home Page", "dropdown": "Home Dropdown" }}
                        open={open}
                        setOpen = {setOpen}
                         />
                    {!isMobile && toggle && <>
                        <span className={styles.triangle}></span>
                        <div className={styles.location} ref={myRef}>
                            <div className={styles.request}>
                                <div>
                                    <Image width="25" height="25" src={"/static/images/home/location_icon.png"} alt="location icom" />
                                </div>
                                <p>Please provide your location for best experience</p>
                            </div>
                            <hr />
                            <div className={styles.detect_location}>
                                <div>
                                    <Image width="20" height="20" src={"/static/images/home/geo_icon.png"} alt="geo icon"/>
                                </div>
                                <p onClick={detectLocation}>Detect My Location</p>
                            </div>
                            {geoError && <div className={styles.error_block}>  <div className={styles.err_img}><Image width="20" height="20" src={"/static/images/home/alert_circle.png"} /></div> <p className={styles.geo_error} >We do not have permission to detect your location. Enter location manually</p></div>}
                            <p className={styles.or}>or</p>
                            <GoogleAutoComplete onChange={(val) => {setSelectedCityInCookie(val,'mannual'); SetLocation(val); setToggle(false); setOpen(true) }} />
                        </div>
                    </>
                    }
                </div>

            </div>
        </Section>
    )
}

export default CtaBanner;
